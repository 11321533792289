<template>
  <section id="main" class="main-section">
    <!-- 프로필 이름 영역 -->
    <div class="name-container">
      <h1 class="name">Hajun Shin</h1>
    </div>

    <!-- 테마를 표현하는 영역 -->
    <div class="themes-container">
      <div class="theme development">
        <div class="theme-content">
          <i class="fas fa-laptop-code"></i>
          <h2>Development</h2>
        </div>
      </div>
      <div class="theme quantum">
        <div class="theme-content">
          <i class="fas fa-atom"></i>
          <h2>Quantum Mechanics</h2>
        </div>
      </div>
      <div class="theme physics">
        <div class="theme-content">
          <i class="fas fa-magnet"></i>
          <h2>Physics</h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MainSection",
};
</script>

<style scoped>
/* 메인 섹션 스타일 */
.main-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: radial-gradient(circle, #1b2735, #090a0f);
  color: white;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  overflow: visible; /* 부모 요소에 overflow를 visible로 설정 */
  padding: 0 20px;
}

/* 이름 및 타이틀 스타일 */
.name-container {
  margin-bottom: 40px;
  animation: fadeInDown 1.5s ease-in-out;
}

.name {
  font-size: 4rem;
  font-weight: 900;
  text-transform: uppercase;
  background: linear-gradient(to right, #ff4b2b, #ff416c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.1em;
  animation: textGlow 5s ease-in-out infinite;
}

/* 테마 컨테이너 */
.themes-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow: visible; /* 요소 커짐에 따라 잘리지 않도록 설정 */
  padding: 0 10px;
  scroll-behavior: smooth;
}

.theme {
  width: 150px;
  height: 150px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  transition: all 0.4s ease;
  z-index: 1;
}

.theme:hover {
  transform: scale(1.1);
  background: linear-gradient(135deg, #ff4b2b, #ff416c);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.6);
  overflow: visible; /* 요소가 커지면서 잘리지 않도록 */
}

.theme-content {
  text-align: center;
  z-index: 1;
  position: relative;
}

.theme i {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: white;
  transition: transform 0.5s ease;
}

.theme:hover i {
  transform: rotate(360deg);
}

.theme h2 {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  transition: color 0.5s ease;
}

/* 반응형 디자인 */
@media screen and (max-width: 1024px) {
  .name {
    font-size: 3.5rem;
  }

  .themes-container {
    gap: 15px;
  }

  .theme {
    width: 130px;
    height: 130px;
  }

  .theme i {
    font-size: 2rem;
  }

  .theme h2 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .name {
    font-size: 3rem;
  }

  .themes-container {
    gap: 10px;
  }

  .theme {
    width: 110px;
    height: 110px;
  }

  .theme i {
    font-size: 1.8rem;
  }

  .theme h2 {
    font-size: 0.9rem;
  }
}

/* 애니메이션 */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes textGlow {
  0%, 100% {
    text-shadow: 0 0 10px rgba(255, 65, 108, 0.7), 0 0 20px rgba(255, 65, 108, 0.5);
  }
  50% {
    text-shadow: 0 0 20px rgba(255, 65, 108, 0.8), 0 0 30px rgba(255, 65, 108, 0.6);
  }
}

</style>
