import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/components/Home.vue'; // Home 컴포넌트 경로
import Physics from '@/components/Physics.vue'; // Physics 컴포넌트 경로
import ComputerScience from '@/components/ComputerScience.vue'; // ComputerScience 컴포넌트 경로
import GameDevelopment from '@/components/GameDevelopment.vue'; // GameDevelopment 컴포넌트 경로

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/physics',
    name: 'Physics',
    component: Physics,
  },
  {
    path: '/computer-science',
    name: 'ComputerScience',
    component: ComputerScience,
  },
  {
    path: '/game-development',
    name: 'GameDevelopment',
    component: GameDevelopment,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth',
        };
      } else {
        return { top: 0, behavior: 'smooth' };
      }
    }
  },
});

export default router;
